<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="
        selectedWarehouse?.fulfilment_app_id === null ||
        selectedWarehouse?.fulfilment_app_id === undefined
      "
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.notFulfillment") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="mt-4 col-12" v-if="items === undefined">
        <div class="nature-white-box no-min-height mb-3">
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else-if="items.length === 0" class="text-center mt-4">
        <Vue3Lottie
          :animationData="successJSON"
          :height="160"
          :width="300"
          :loop="false"
        />
        <div class="bold-40 m-2 p-5">
          {{ $t("productHistory.noProblems") }}
        </div>
      </div>
      <div v-else>
        <div
          class="row nature-white-box no-min-height mb-3"
          v-for="item in items"
          :key="item.id"
        >
          <div class="col-4">
            {{ item.serial }}
          </div>
          <div class="col-2">
            {{ item.sku }}
          </div>
          <div class="col-2">
            {{ formatDate(item.fulfillment_quantity_at) }}
          </div>
          <div class="col-4 text-end">
            <div>
              Fulfillment quantity:
              <span class="bold-24">
                {{ item.fulfillment_quantity }}
              </span>
            </div>
            <div>
              Prodyflow quantity:
              <span class="bold-24">
                {{ item.product_quantity }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import http from "@/modules/http";
import date from "@/modules/date";
import successJSON from "../../assets/animations/success.json";
import { Vue3Lottie } from "vue3-lottie";

export default {
  name: "inventoryShortage",
  components: { Vue3Lottie },
  data() {
    return {
      store: useStore(),
      successJSON: successJSON,
      items: undefined,
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    warehouse() {
      this.loadData();
    },
  },
  methods: {
    formatDate(val) {
      return date.format(val, true, false);
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    loadData() {
      if (this.warehouse === null || this.warehouse === undefined) {
        this.items = undefined;
        return;
      }
      let url = `/warehouses/outgoing/inventory-shortage?warehouse=${this.warehouse}`;
      http.fetch(url).then((data) => {
        this.items = data;
      });
    },
  },
};
</script>
